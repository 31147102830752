import {Route, Routes} from '@angular/router';

export const routes: Routes = [

  // 👇 ❌ Introduce Route + Possible Layout Module

  <Route>{ path: '', loadChildren: () => import('./views/main/main.module').then(m => m.MainModule) },
  <Route>{ path: 'guide', loadChildren: () => import('./views/guide/guide.module').then(m => m.GuideModule) },
  <Route>{ path: 'main', loadChildren: () => import('./views/main/main.module').then(m => m.MainModule) },

];
