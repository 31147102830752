import {inject, Injectable, InjectionToken} from "@angular/core";
import {TargetEnvironments, TargetEnvironmentType} from "@mods/shared";
import {HttpHandler, HttpRequest} from "@angular/common/http";

export const TARGET_API_ENVIRONMENT = new InjectionToken<TargetEnvironmentType>('TARGET_API_ENVIRONMENT');

@Injectable()
export class BaseUrlInterceptor {

  private readonly targetEnvironment = inject(TARGET_API_ENVIRONMENT, {optional: true});
  private hasScheme = (url: string) => this.targetApiUrl && new RegExp('^http(s)?://', 'i').test(url);

  get targetApiUrl() {
    return this.targetEnvironment ? TargetEnvironments[this.targetEnvironment] : null;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return this.hasScheme(request.url) === false
      ? next.handle(request.clone({url: this.prependBaseUrl(request.url)}))
      : next.handle(request);
  }

  private prependBaseUrl(url: string) {
    return [this.targetApiUrl?.replace(/\/$/g, ''), url.replace(/^\.?\//, '')]
      .filter(val => val)
      .join('/');
  }
}
