import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from "@angular/common";

export type AppTheme = "lara-light" | "lara-dark";
export type AppThemeKey = "laraLight" | "laraDark";

export const ApplicationThemes: Record<AppThemeKey, AppTheme> = {
  laraLight: "lara-light",
  laraDark: "lara-dark"
};
export const ApplicationThemeNames: Record<AppThemeKey, AppThemeKey> ={
  laraLight: "laraLight",
  laraDark: "laraDark"
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  doc = inject(DOCUMENT);
  themeDomElementSelector = 'app-theme';

  get themeElement() {
    return this.doc.getElementById(this.themeDomElementSelector) as HTMLLinkElement;
  }
  toggleTheme(theme: AppTheme) {
    this.themeElement.href = `${theme}.css`;
  }
  toggle(themeName: AppThemeKey) {
    const theme = ApplicationThemes[themeName];
    this.toggleTheme(theme);
  }
  toggleLight() {
    this.toggle(ApplicationThemeNames.laraLight);
  }
  toggleDark() {
    this.toggle(ApplicationThemeNames.laraDark);
  }
}
