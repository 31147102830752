<!--<p-toolbar  >-->
<!--  <ng-template pTemplate="start">-->
<!--    <img src="https://primefaces.org/cdn/primeng/images/primeng.svg"  alt=""/>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="center">-->
<!--    <div class="actions">-->
<!--    </div>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="end">-->
<!--    <div class="flex align-items-center gap-2">-->
<!--      <div class="flex align-items-center gap-2 scale-8">-->
<!--        <p-inputSwitch (onChange)="changeTheme($event)"/>-->
<!--        <span>-->
<!--        Dark-->
<!--      </span>-->
<!--      </div>-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</p-toolbar>-->
<router-outlet />
