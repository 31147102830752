import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import { routes } from './app.routes';
import {PrimeNgModule} from "./modules/prime-ng/prime-ng.module";
import {NgxsModule} from "@ngxs/store";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {httpInterceptorProviders, TARGET_API_ENVIRONMENT} from "@mods/shared/interceptors";
import {environment} from "../environments/environment";
import {All} from "./states";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    { provide: TARGET_API_ENVIRONMENT, useValue: environment.targetEnvironment },
    httpInterceptorProviders,
    importProvidersFrom(
      PrimeNgModule,
      NgxsModule.forRoot([
        ...All()
      ]),
      NgxsReduxDevtoolsPluginModule.forRoot()
    )
  ]
};
