import {Component, inject} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {PrimeTemplate} from "primeng/api";
import {PrimeNgModule} from "./modules/prime-ng/prime-ng.module";
import {CommonModule} from "@angular/common";
import {InputSwitchChangeEvent} from "primeng/inputswitch";
import {ThemeService} from "./shared/services";

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet
  ],
  styleUrl: './app.component.scss'
})
export class AppComponent {
  themeService = inject(ThemeService);
  title = 'web';

  changeTheme($event: InputSwitchChangeEvent) {
    if($event.checked) {
      this.themeService.toggleDark();
      return;
    }
    this.themeService.toggleLight()
  }
}
